import React, { FC } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage: FC<{}> = () => (
  <Layout>
    <SEO title="404: Not found" slug="404" />
    <h1>Page not found</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
    <p>The sadness.. The darkness.. The misery..</p>
    <p>Hopefully, it won't happen again.</p>
    <p>Ever..</p>
  </Layout>
);

export default NotFoundPage;
